import LocalizedStrings from 'react-localization'

let locales = new LocalizedStrings({
  en: {
    descrizioneIniziale:
      'GLW WATCH is a leading company specializing in the buying and selling of a wide range of products. Our extensive catalog includes everything from high-demand consumer electronics to photovoltaic panels. We are dedicated to providing our clients with the best products at competitive prices, ensuring quality and satisfaction.',
    titolo1: 'Our Services',
    sottotitolo1: 'Comprehensive Product Range',
    descrizione1:
      'We offer a diverse selection of products to meet the needs of various markets.',
    sottotitolo2: 'Competitive Pricing',
    descrizione2:
      'We pride ourselves on offering competitive pricing on all our products.',
    sottotitolo3: 'Customer Satisfaction',
    descrizione3:
      'Our dedicated team provides exceptional service and support, ensuring that every transaction is smooth and hassle-free.',
    titolo4: 'Fast and Reliable Delivery',
    descrizione4:
      "We understand the importance of timely delivery. That's why we guarantee fast and reliable shipping for all orders, ensuring that you receive your products as quickly as possible.",
    titolo5: 'Custom Solutions',
    descrizione5:
      'At GLW WATCH, we offer tailored solutions to meet the specific needs of our clients. From custom product sourcing to personalized customer support, we are here to help your business thrive.',
    titolo6: 'Contact Us',
    descrizione6:
      'Get in touch with us today to learn more about our products and services. Our team is ready to assist you with any inquiries and provide the support you need.',
    contact: 'Contact Us',
  },
  it: {
    descrizioneIniziale:
      "GLW WATCH è un'azienda leader specializzata nella compravendita di una vasta gamma di prodotti. Il nostro catalogo esteso include di tutto, dall'elettronica di consumo ad alta richiesta ai pannelli fotovoltaici. Siamo dedicati a fornire ai nostri clienti i migliori prodotti a prezzi competitivi, garantendo qualità e soddisfazione.",
    titolo1: 'I nostri servizi',
    sottotitolo1: 'Gamma completa di prodotti',
    descrizione1:
      'Offriamo una selezione diversificata di prodotti per soddisfare le esigenze di vari mercati.',
    sottotitolo2: 'Prezzi competitivi',
    descrizione2:
      'Ci vantiamo di offrire prezzi competitivi su tutti i nostri prodotti.',
    sottotitolo3: 'Soddisfazione del cliente',
    descrizione3:
      'Il nostro team dedicato fornisce un servizio eccezionale e supporto, garantendo che ogni transazione sia fluida e senza problemi.',
    titolo4: 'Consegna rapida e affidabile',
    descrizione4:
      "Comprendiamo l'importanza della consegna tempestiva. Ecco perché garantiamo spedizioni rapide e affidabili per tutti gli ordini, assicurandoci che riceviate i vostri prodotti il più rapidamente possibile.",
    titolo5: 'Soluzioni personalizzate',
    descrizione5:
      "In GLW WATCH offriamo soluzioni su misura per soddisfare le specifiche esigenze dei nostri clienti. Dalla ricerca personalizzata dei prodotti all'assistenza clienti personalizzata, siamo qui per aiutare la vostra attività a prosperare.",
    titolo6: 'Contattaci',
    descrizione6:
      'Contattaci oggi stesso per saperne di più sui nostri prodotti e servizi. Il nostro team è pronto ad assisterti con qualsiasi domanda e fornire il supporto necessario.',
    contact: 'Contattaci',
  },
})

export default locales
