import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Space, Switch } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { memo, useEffect, useState } from 'react'
import logoBianco from '../images/logo_bianco.png'
import logo from '../images/logo.png'
import './../App.css'
import locales from '../locales/locale'

const Menu = ({
  setLang,
  lang,
}: {
  setLang: React.Dispatch<React.SetStateAction<string>>
  lang: string
}): JSX.Element => {
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setIsFixed(scrollTop > 0) // Imposta isFixed a true se lo scroll è maggiore di 0
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isFixedClassName = isFixed ? 'is-fixed' : ''

  return (
    <Header id="header" className={isFixedClassName}>
      <div className={`info-menu ${isFixedClassName}`}>
        <div style={{ float: 'right' }}>
          <Space
            style={{
              borderRight: '1px #ffffff3d solid',
              padding: '0 1vw',
            }}
            size={'small'}
          >
            <PhoneOutlined />
            <a href="tel:+393425776198" style={{ color: 'white' }}>
              +39 3425776198
            </a>
          </Space>
          <Space
            style={{
              padding: '0 1vw',
            }}
            size={'small'}
          >
            <MailOutlined />
            <a href="mailto:info@glw-watch.it" style={{ color: 'white' }}>
              info@glw-watch.it
            </a>
          </Space>
        </div>
      </div>
      <Space
        direction="horizontal"
        className="menu-items"
        // align="start"
        style={{
          padding: 8,
          color: isFixed ? 'black' : 'white',
        }}
      >
        <img
          src={isFixedClassName ? logo : logoBianco}
          alt="Sfondo"
          className={`logo ${isFixedClassName}`}
        />
        <div className="text-logo">
          <span>
            <b style={{ color: '#336633' }}>GLW</b>
          </span>
          <span style={{ color: '#ffcc00' }}>WATCH</span>
        </div>
      </Space>
      <Switch
        className={`lingua ${isFixedClassName}`}
        checked={lang === 'en'}
        onChange={(language) => {
          setLang(language ? 'en' : 'it')
          locales.setLanguage(language ? 'en' : 'it')
        }}
        checkedChildren="EN"
        unCheckedChildren="IT"
      />
    </Header>
  )
}

export default memo(Menu)
