import {
  DollarOutlined,
  MailOutlined,
  PhoneOutlined,
  ProductOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import './App.css'
import { Col, Layout, Row, Space } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import Menu from './components/menu'
import locales from './locales/locale'
import elettronica from './images/delivery.jpg'
import { useState } from 'react'
import { Fade } from 'react-awesome-reveal'

function App() {
  const [language, setLanguage] = useState(locales.getLanguage())

  return (
    <Layout>
      <Menu setLang={setLanguage} lang={language} />
      <Content style={{ zIndex: 1 }}>
        <div className="background">
          <Space
            className="primoParagrafo"
            direction="vertical"
            align="start"
            size="small"
          >
            <p>{locales.descrizioneIniziale}</p>
          </Space>
          <svg
            className="white-svg"
            viewBox="0 0 100 10"
            preserveAspectRatio="none"
          >
            <path d="M0 10 0 0 A 90 59, 0, 0, 0, 100 0 L 100 10 Z"></path>
          </svg>
        </div>
        <Fade>
          <div className="paragrafo" style={{ minHeight: '100vh' }}>
            <div style={{ width: '90%', textAlign: 'center' }}>
              <h1 style={{ color: '#336633', fontSize: 'clamp(5vh,5vw,5vw)' }}>
                {locales.titolo1}
              </h1>
            </div>
            <div
              className="pallini"
              style={{
                textAlign: 'center',
                width: '90%',
                display: 'flex',
              }}
            >
              <div className="box-pallino">
                <div style={{ placeContent: 'center' }}>
                  <span className="dot">
                    <ProductOutlined />
                  </span>
                </div>
                <h2 style={{ color: '#336633' }}>{locales.sottotitolo1}</h2>
                <p>{locales.descrizione1}</p>
              </div>
              <div className="box-pallino">
                <div style={{ placeContent: 'center' }}>
                  <span className="dot">
                    <DollarOutlined />
                  </span>
                </div>
                <h2 style={{ color: '#336633' }}>{locales.sottotitolo2}</h2>
                <p>{locales.descrizione2}</p>
              </div>
              <div className="box-pallino">
                <div style={{ placeContent: 'center' }}>
                  <span className="dot">
                    <TeamOutlined />
                  </span>
                </div>
                <h2 style={{ color: '#336633' }}>{locales.sottotitolo3}</h2>
                <p>{locales.descrizione3}</p>
              </div>
            </div>
            {/* <img alt="By Karolina Grabowska" src={team} /> */}
          </div>
        </Fade>
        <div
          className="paragrafo sfondoRosso"
          style={{
            height: '120vh',
          }}
        >
          <div>
            <h1>{locales.titolo4}</h1>
            <p>{locales.descrizione4}</p>
          </div>
          <img alt="By Ketut Subiyanto" src={elettronica} />
        </div>
        <div
          style={{ textAlign: 'start' }}
          className="paragrafo reverse noPadding"
        >
          <div>
            <h1>{locales.titolo5}</h1>
            <p>{locales.descrizione5}</p>
          </div>
          {/* Photo by cottonbro studio: https://www.pexels.com/photo/person-wearing-gray-coverall-holding-a-wrench-7006694/ */}
          <div className="analisi" />
        </div>
        <div
          className="paragrafo sfondoRosso"
          style={{ textAlign: 'center', minHeight: '50vh' }}
        >
          <div style={{ width: '80%', paddingBottom: 0 }}>
            <Fade>
              <h1>{locales.titolo6}</h1>
              <p>{locales.descrizione6}</p>
            </Fade>
          </div>
        </div>
      </Content>
      <Footer>
        <Row style={{ placeContent: 'center' }}>
          <Col>
            <Space
              style={{
                borderRight: '1px #ffffff3d solid',
                padding: '0 1vw',
              }}
              size={'small'}
            >
              <PhoneOutlined />
              <a href="tel:+393425776198" style={{ color: 'white' }}>
                +39 3425776198
              </a>
            </Space>
          </Col>
          <Col>
            <Space
              style={{
                padding: '0 1vw',
              }}
              size={'small'}
            >
              <MailOutlined />
              <a href="mailto:info@glw-watch.it" style={{ color: 'white' }}>
                info@glw-watch.it
              </a>
            </Space>
          </Col>
        </Row>
        <Row style={{ placeContent: 'center', marginTop: '1vw' }}>
          <Col
            style={{
              padding: '0 1vw',
            }}
          >
            <a
              style={{ color: 'white', fontWeight: 'bold' }}
              target="_blank"
              href="https://www.termsfeed.com/live/cab43f89-8ae6-4f5e-9b97-1282b55ce9a8"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </Col>
          <Col
            style={{
              padding: '0 1vw',
            }}
          >
            <a
              style={{ color: 'white', fontWeight: 'bold' }}
              target="_blank"
              href="https://www.cookiepolicygenerator.com/live.php?token=YIuQzUeTKN59BTJpSzJSkKadwYwVzVsU"
              rel="noreferrer"
            >
              Cookie policy
            </a>
          </Col>
        </Row>
      </Footer>
    </Layout>
  )
}

export default App
